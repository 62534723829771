import './animations.css';
import './navbar.css';
import foxAndCrowImage from './logo32.svg'; // Import your SVG image
import textContentImage1 from './fulltext2.svg'; // Import your first SVG text content image
import logoImage from './tafouio.svg'; // Import your logo SVG
import projectsIcon from './project.svg'; // Import your projects SVG
import roadmapIcon from './roadmap.svg'; // Import your roadmap SVG
import futureIcon from './future.svg'; // Import your future SVG
import aboutIcon from './about.svg'; // Import your about SVG
import main2 from './project2.svg';
import main3 from './main31.svg';
import main4 from './main4.svg';
import main5 from './aboutcontent.svg';
// import footerImage from './foot.svg'; // Adjust the path as necessary
// import React, { useEffect,useRef } from 'react';
import { useEffect, useMemo, useState,useRef } from "react";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadSlim } from "@tsparticles/slim";
import './App.css'; // Make sure to include your CSS file
import './footer.css'; // Assuming you have a separate CSS file for footer styles
import upArrow from './uparrow.svg';


// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDgtPa3Wt3trv6gwTHATvSf2u9LBzuSy7I",
  authDomain: "tafouio-reactjs.firebaseapp.com",
  projectId: "tafouio-reactjs",
  storageBucket: "tafouio-reactjs.appspot.com",
  messagingSenderId: "70609393470",
  appId: "1:70609393470:web:6baa334a29338f6e527e6f",
  measurementId: "G-2XGFETC7NM"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);


const smoothScrollTo = (element, duration, offset = 0) => {
  // Subtract 'offset' from the target position for additional space above the target
  const targetPosition = element.getBoundingClientRect().top - offset;
  const startPosition = window.pageYOffset;
  const distance = targetPosition - startPosition;
  let startTime = null;

  const animation = currentTime => {
    if (startTime === null) startTime = currentTime;
    const timeElapsed = currentTime - startTime;
    const run = easeInOutQuad(timeElapsed, startPosition, distance, duration);
    window.scrollTo(0, run);
    if (timeElapsed < duration) requestAnimationFrame(animation);
  };

  const easeInOutQuad = (t, b, c, d) => {
    t /= d / 2;
    if (t < 1) return c / 2 * t * t + b;
    t--;
    return -c / 2 * (t * (t - 2) - 1) + b;
  };

  requestAnimationFrame(animation);
};



const ParticlesBackground = () => {
  const [init, setInit] = useState(false);

  // this should be run only once per application lifetime
  useEffect(() => {
    initParticlesEngine(async (engine) => {

      await loadSlim(engine);

    }).then(() => {
      setInit(true);
    });
  }, []);

  const particlesLoaded = (container) => {
    console.log(container);
  };

  const options = useMemo(
    () => ({
      fpsLimit: 120,
      interactivity: {
        events: {
          onClick: {
            enable: false,
            mode: "push",
          },
          onHover: {
            enable: false,
            mode: "repulse",
          },
        },
        modes: {
          push: {
            quantity: 4,
          },
          repulse: {
            distance: 150,
            duration: 0.4,
          },
        },
      },
      particles: {
        color: {
          value: "#ffffff",
        },
        links: {
          color: "#ffffff",
          distance: 200,
          enable: true,
          opacity: 0.2,
          width: 1,
        },
        move: {
          direction: "none",
          enable: true,
          outModes: {
            default: "bounce",
          },
          random: false,
          speed: 0.5,
          straight: false,
        },
        number: {
          density: {
            enable: true,
          },
          value: 100,
        },
        opacity: {
          value: 0.2,
        },
        shape: {
          type: "circle",
        },
        size: {
          value: { min: 1, max: 3 },
        },
      },
      detectRetina: true,
    }),
    [],
  );

  if (init) {
    return (
      <div className={'fade-in-part'}>
        <Particles
          id="tsparticles"
          particlesLoaded={particlesLoaded}
          options={options}
        />
      </div>
    );
  }

  return <></>;
};


function App() {
  const main2ContainerRef = useRef(null); // Projects
  const main3ContainerRef = useRef(null); // Roadmap
  const main4ContainerRef = useRef(null); // Future
  const main5ContainerRef = useRef(null); // About

  const scrollToSection = (ref) => {
    if (ref.current) {
      smoothScrollTo(ref.current, 600, 120);
    }
  };

  return (
    <div className="container">
      <Navbar
        onProjectsClick={() => scrollToSection(main2ContainerRef)}
        onRoadmapClick={() => scrollToSection(main3ContainerRef)}
        onFutureClick={() => scrollToSection(main4ContainerRef)}
        onAboutClick={() => scrollToSection(main5ContainerRef)}
      />
      <MainContent
        main2ContainerRef={main2ContainerRef}
        main3ContainerRef={main3ContainerRef}
        main4ContainerRef={main4ContainerRef}
        main5ContainerRef={main5ContainerRef}
      />
      <Footere/>
      <ParticlesBackground />
    </div>
  );
}

function Navbar({ onProjectsClick, onRoadmapClick, onFutureClick, onAboutClick }) {
  return (
    <nav>
      <img src={logoImage} alt="tafou.io" className="logo-svg" onContextMenu={disableRightClick} onDragStart={preventDragHandler}/>
      <div className="nav-links" onContextMenu={disableRightClick} onDragStart={preventDragHandler}>
        <img src={projectsIcon} alt="Projects" className="nav-icon smaller-project" onClick={onProjectsClick} />
        <img src={roadmapIcon} alt="Roadmap" className="nav-icon" onClick={onRoadmapClick} />
        <img src={futureIcon} alt="Future" className="nav-icon smaller-icon" onClick={onFutureClick} /> {/* Add a class for smaller icons */}
        <img src={aboutIcon} alt="About" className="nav-icon smaller-about" onClick={onAboutClick} /> {/* Add a class for smaller icons */}
      </div>
    </nav>
  );
}

const Footere = () => {
  return (
    <footer className="footer">
      <div className="footer-section copyright">
        Copyright © 2024 by Tzelal OUZEIR. All Rights Reserved
      </div>
      <div className="footer-right-sections">
        <div className="footer-section follow-us">
          <p>Social Media</p>
          <a href="https://t.me/tafouio">Telegram</a>
          <a href="https://twitter.com/tafouio">Twitter</a>
          <a href="https://www.tiktok.com/@tafou.io">Tiktok</a>
          <a href="https://www.linkedin.com/in/tzelalouzeir/">LinkedIn</a>
        </div>
        <div className="footer-section ecosystem">
          <p>Ecosystem</p>
          <a href="https://algo.tafou.io/Community">Community</a>
          <a href="https://algo.tafou.io/Docs">Docs</a>
        </div>
        {/* <div className="footer-section comingsoon">
          <p>Up Coming!</p>
          <a>Learn</a>
          <a>Support Program</a>
          <a>tafouBlog</a>
        </div> */}
        <div className="footer-section projects">
          <p>Projects</p>
          <a href="https://algo.tafou.io">tafouAlgo</a>
          <a>tafouLive (Merge)</a>
        </div>
        <div className="footer-section support">
          <p>Support</p>
          <a href="https://algo.tafou.io/Help%20Center">FAQs</a>
          <a href="https://algo.tafou.io/Help%20Center">Contact Support</a>
          <a href="https://algo.tafou.io/Help%20Center">Feedback</a>
          <a href="https://algo.tafou.io/Help%20Center">Terms of Service</a>
          <a href="https://algo.tafou.io/Help%20Center">Privacy Policy</a>
        </div>
      </div>
    </footer>
  );
};

// const Footer = () => {
//   return (
//     <footer>
//       <img src={footerImage} alt="Footer" className="footer-image"onContextMenu={disableRightClick} onDragStart={preventDragHandler} />
//     </footer>
//   );
// };


const disableRightClick = (e) => {
  e.preventDefault();
};
const preventDragHandler = (e) => {
  e.preventDefault();
};



function MainContent({main2ContainerRef,main3ContainerRef,main4ContainerRef,main5ContainerRef}) {
  const mainContentRef = useRef(null);
  const [showScrollTopBtn, setShowScrollTopBtn] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setShowScrollTopBtn(window.pageYOffset > 300);
    };
  
    window.addEventListener('scroll', handleScroll);
  
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('fade-in');
            entry.target.classList.remove('fade-out');
          } else {
            entry.target.classList.remove('fade-in');
            entry.target.classList.add('fade-out');
          }
        });
      },
      {
        threshold: 0.2 // Adjust threshold to control when the animation triggers
      }
    );

    const refs = [mainContentRef, main2ContainerRef, main3ContainerRef, main4ContainerRef, main5ContainerRef];
    refs.forEach(ref => {
      if (ref.current) {
        observer.observe(ref.current);
      }
    });
  
    return () => {
      refs.forEach(ref => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      });
    };
  }, []);

  return (
    <div className="content-wrapper" onContextMenu={disableRightClick} onDragStart={preventDragHandler}>
      <div ref={mainContentRef} className="main-content fade-out">
        <img src={foxAndCrowImage} alt="Fox and Crow" className="responsive-image" />
        <div className="text-image-container">
          <img src={textContentImage1} alt="Text Content 1" className="text-image" />
        </div>
      </div>
      <div ref={main2ContainerRef} className="main2-container style={{ opacity: 0 }}"onContextMenu={disableRightClick} onDragStart={preventDragHandler}>
        <img src={projectsIcon} alt="Projects" className="projects-icon"  />
        <img src={main2} alt="Main Content 2" className="main2-image"  />
      </div>
      <div ref={main3ContainerRef} className="main2-container fade-out"onContextMenu={disableRightClick} onDragStart={preventDragHandler}>
        <img src={roadmapIcon} alt="Roadmap" className="projects-icon" />
        <img src={main3} alt="Main Content 3" className="main3-image"  />
      </div>
      <div ref={main4ContainerRef} className="main2-container fade-out" onContextMenu={disableRightClick} onDragStart={preventDragHandler}>
        <img src={futureIcon} alt="Future" className="projects-icon" />
        <img src={main4} alt="Main Content 4" className="main4-image" />
      </div>
      <div ref={main5ContainerRef} className="main2-container-foot fade-out" onContextMenu={disableRightClick} onDragStart={preventDragHandler}>
        <img src={aboutIcon} alt="About" className="projects-icon" />
        <img src={main5} alt="Main Content 5" className="main4-image" />
      </div>
      <img
        src={upArrow}
        alt="Scroll to top"
        className={`scroll-to-top-icon ${showScrollTopBtn ? 'visible' : ''}`}
        onClick={scrollToTop}
      />
    </div>
  );
}



export default App;
